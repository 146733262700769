<template>
  <section data-component-name="Awards">
    <div class="wrapper">
      <h3 v-html="$t('Awards.title')" />

      <Buttons.Regular
        :aria-label="$t('Awards.prev-btn.title')"
        fill="outline"
        class="awards-swiper-prev-slide"
      >
        <SvgIcon name="arrow-backward" />
      </Buttons.Regular>

      <Buttons.Regular
        :aria-label="$t('Awards.next-btn.title')"
        fill="outline"
        class="awards-swiper-next-slide"
      >
        <SvgIcon name="arrow-forward" />
      </Buttons.Regular>
    </div>

    <Swiper
      :modules="[Navigation]"
      :slides-offset-before="sliderOffset"
      :slides-offset-after="sliderOffset"
      v-bind="SWIPER_CONFIG"
      :navigation="{
        prevEl: '.awards-swiper-prev-slide',
        nextEl: '.awards-swiper-next-slide',
      }"
      class="awardsSwiper"
    >
      <SwiperSlide
        v-for="({ href, ...imgAttrs }, index) of AWARDS"
        :key="index"
      >
        <NuxtLink
          :to="href.startsWith('https') ? href : localePathWithSlash(href)"
          target="_blank"
        >
          <NuxtImg
            v-bind="imgAttrs"
            densities="x1 x2"
            loading="lazy"
            format="webp"
            fit="contain"
          />
        </NuxtLink>
      </SwiperSlide>
    </Swiper>
  </section>
</template>

<script lang="ts" setup>
// components
import Buttons from '~/components/redesign/Buttons';

// swiper
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// composables
import { useI18n } from '#i18n';
import { useWindowSize } from '@vueuse/core';
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

const { t } = useI18n();
const localePathWithSlash = useLocalePathWithSlash();

const AWARDS = [
  {
    href: 'https://www.ciocoverage.com/10-fastest-growing-zero-trust-security-solution-providers-2024-25/',
    src: 'redesign/images/awards/CIO_COVERAGE_ZERO_TRUST.webp',
    alt: t('Awards.award-0.alt'),
    sizes: 'xs:128px xl:256px',
  },
  {
    href: 'https://storage-awards.com/',
    src: 'redesign/images/awards/STORAGE_AWARDS.webp',
    alt: t('Awards.award-1.alt'),
    sizes: 'xs:128px xl:256px',
  },
  {
    href: '/newsroom/press-releases/vitaly-sukhovsky-recognized-as-2024-crn-channel-chief/',
    src: 'redesign/images/awards/CRN_CHANNEL_2024.webp',
    alt: t('Awards.award-4.alt'),
    sizes: 'xs:80px xl:160px',
  },
  {
    href: '/newsroom/press-releases/object-first-selected-for-2024-crn-partner-program-guide/',
    src: 'redesign/images/awards/CRN_PPG_2024.webp',
    alt: t('Awards.award-3.alt'),
    sizes: 'xs:68px xl:135px',
  },
  {
    href: 'https://www.crn.com/news/storage/2024/storage-100-the-digital-bridge-between-the-cloud-and-on-premises-worlds',
    src: 'redesign/images/awards/CRN_100_2024.webp',
    alt: t('Awards.award-2.alt'),
    sizes: 'xs:65px xl:130px',
  },
  {
    href: '/newsroom/press-releases/crn-recognizes-meredith-frick-and-kelly-wells-on-the-2024-women-of-the-channel-list/',
    src: 'redesign/images/awards/CRN_WOMAN_CHANNEL.webp',
    alt: t('Awards.award-5.alt'),
    sizes: 'xs:81px xl:162px',
  },
  {
    href: 'https://storage-awards.com/',
    src: 'redesign/images/awards/STORAGE_AWARDS_2.webp',
    alt: t('Awards.award-6.alt'),
    sizes: 'xs:128px xl:256px',
  },
  {
    href: 'https://www.crn.com/news/storage/stellar-startup-storage-vendors-to-know-in-2023?page=4',
    src: 'redesign/images/awards/CRN_STARTUPS_2023.webp',
    alt: t('Awards.award-7.alt'),
    sizes: 'xs:92px xl:184px',
  },
  {
    href: 'https://techtrailblazers.com/shortlist/',
    src: 'redesign/images/awards/TT23_FINALIST.webp',
    alt: t('Awards.award-8.alt'),
    sizes: 'xs:128px xl:256px',
  },
];

const SWIPER_CONFIG: Partial<InstanceType<typeof Swiper>> = {
  slidesPerView: 'auto',
  breakpoints: {
    1280: {
      spaceBetween: 32,
    },
    768: {
      spaceBetween: 24,
    },
    0: {
      spaceBetween: 8,
    },
  },
};
const { width: screenWidth } = useWindowSize();
const sliderOffset = computed(() => {
  const width = screenWidth.value;
  if (width > 1280) {
    return (width - 1216) / 2;
  } else if (width > 767) {
    return 32;
  } else {
    return 16;
  }
});
</script>

<style scoped lang="scss">
@use "_/mixins/flex";
@use "_/mixins/size";
@use "_/mixins/media";
@use "_/mixins/typo";
@use "_/variables/shadow";
@use "_/fn";

[data-component-name="Awards"] {
  position: relative;
  padding: 8rem 0 5rem;
  background-color: fn.token("surf-cont-secondary");

  @include media.tablet {
    padding: 6rem 0 3rem;
  }

  @include media.mobile {
    padding: 4rem 0;
  }

  .wrapper {
    @include size.wrapper(80rem);
    padding: 0 2rem;
    @include flex.center-start;

    @include media.mobile {
      padding: 0 1rem;
    }

    h3 {
      @include typo.h3;
      margin-right: auto;
    }

    [data-component-name="Buttons.Regular"] {
      width: 3.5rem;
      height: 3.5rem;

      @include media.mobile {
        position: absolute;
        bottom: 2.4rem;

        &:first-of-type,
        &:last-of-type {
          width: calc(50% - 1.25rem);
          z-index: 2;
        }

        &:first-of-type {
          left: 1rem;
        }

        &:last-of-type {
          right: 1rem;
        }
      }

      &:last-of-type {
        margin-left: 1rem;
      }

      svg {
        fill: fn.token("icon-primary");
      }
    }
  }

  :deep(.swiper) {
    .swiper-wrapper {
      padding: 3.5rem 0 3rem;

      @include media.tablet {
        padding: 3rem 0 3rem;
      }

      @include media.mobile {
        padding: 2rem 0 4rem;
      }

      .swiper-slide {
        @include size.fixed(16rem);

        @include media.tablet {
          @include size.fixed(8rem);
        }

        a {
          @include flex.center-center;
          @include size.full;

          img {
            transition: box-shadow 0.3s ease;

            &:hover {
              box-shadow: shadow.$elevation-3;
            }
          }
        }
      }
    }
  }
}
</style>
